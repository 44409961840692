import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Author, Clue, Crossword } from "config/types";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { useWindowSize } from "@react-hook/window-size";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";
import useMeasure from "react-use-measure";
import {
  CrosswordGrid,
  CrosswordProvider,
  CrosswordProviderImperative,
  DirectionClues,
} from "@dpt/react-crossword";
import { ClueTypeOriginal, CellData } from "@dpt/react-crossword/dist/types";
import smoothscroll from "smoothscroll-polyfill";
import FreestarAdSlot from "components/freestar";
import "./style.css";

smoothscroll.polyfill();

const CrosswordPage = () => {
  const params = useParams();
  const [width, height] = useWindowSize();

  const [crossword, setCrossword] = useState<Crossword | undefined>();
  const [dims, setDims] = useState({ width: 0, height: 0 });
  const [authors, setAuthors] = useState<string | undefined>();
  const [clues, setClues] = useState<any | undefined>();
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  const [measureRef, bounds] = useMeasure();
  const onContainerRefChange = useCallback(
    (node: HTMLElement) => {
      measureRef(node);
      if (node !== null && width > 768) {
        node.style.width = `${bounds.height}px`;
      }
    },
    [width, measureRef, bounds],
  );
  const crosswordRef = useRef<CrosswordProviderImperative | null>(null);
  const acrossCluesRef = useRef<HTMLDivElement | null>(null);
  const downCluesRef = useRef<HTMLDivElement | null>(null);

  const [checking, setChecking] = useState<boolean>(false);

  const transformClue = (clue: Clue): ClueTypeOriginal & { id: string } => {
    return {
      clue: clue.clue,
      answer: clue.answer,
      row: clue.y,
      col: clue.x,
      id: clue.id,
    };
  };

  useEffect(() => {
    fetch(`/api/crosswords/${params.id}`)
      .then((res) => res.json())
      .then((data) => {
        setCrossword(data);
        fetch(`/api/crosswords/${params.id}/authors`)
          .then((res) => res.json())
          .then((authors: Author[]) => authors.map((author: Author) => `${author.first_name} ${author.last_name}`))
          .then((authors) => authors.join(" and "))
          .then((authors) => setAuthors(authors))
          .catch((err) => console.error(err));
        fetch(`/api/crosswords/${params.id}/clues`)
          .then((res) => res.json())
          .then((clues: Clue[]) => {
            // get the dimensions of the crossword
            const crosswordDims = clues.reduce(
              (dims, clue) => {
                return {
                  width: Math.max(dims.width, clue.x + (clue.is_across ? clue.answer.length : 1)),
                  height: Math.max(dims.height, clue.y + (clue.is_across ? 1 : clue.answer.length)),
                };
              },
              { width: 0, height: 0 },
            );
            setDims(crosswordDims);
            // transform to match react-crossword component data format
            // get the numbers for each clue
            const clueNumbers = new Map<string, string>();
            clues.sort((c1, c2) => {
              if (c1.y === c2.y) return c1.x - c2.x;
              return c1.y - c2.y; // sort by y first, then x (row by row)
            });
            let i = 0;
            clues.reduce((lastClue, clue) => {
              if (!lastClue || lastClue.x !== clue.x || lastClue.y !== clue.y) i++;
              clueNumbers.set(clue.id, i.toString());
              return clue;
            }, undefined as Clue | undefined);

            let cluesInput = {
              across: clues.filter((clue) => clue.is_across).map((clue) => transformClue(clue)),
              down: clues.filter((clue) => !clue.is_across).map((clue) => transformClue(clue)),
            };
            const cluesMap = {
              across: cluesInput.across.reduce((map, obj) => {
                const { id, ...objWithoutId } = obj;
                map[clueNumbers.get(id)!] = objWithoutId;
                return map;
              }, {} as any),
              down: cluesInput.down.reduce((map, obj) => {
                const { id, ...objWithoutId } = obj;
                map[clueNumbers.get(id)!] = objWithoutId;
                return map;
              }, {} as any),
            };
            setClues(cluesMap);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }, [params]);

  const handleComplete = useCallback(
    (correct: boolean) => {
      if (correct) setShowConfetti(true);
    },
    [setShowConfetti],
  );

  if (!crossword || !authors || !clues) {
    return (
      <Typography sx={{ textAlign: "center" }} variant="body1">
        Loading...
      </Typography>
    );
  }
  const dateStr = new Date(crossword.date).toLocaleDateString("en-us", { timeZone: "UTC", dateStyle: "medium" });

  const handleCellFocus = (row: number, col: number, cellData: CellData) => {
    if (!!!cellData.used) return; // lol
    if (acrossCluesRef.current) {
      const clue = document.querySelector(`div[aria-label="clue-${cellData.across}-across"]`) as HTMLElement;
      if (clue) {
        acrossCluesRef.current!.scrollTo({ top: clue.offsetTop, behavior: "smooth" });
      }
    }
    if (downCluesRef.current) {
      const clue = document.querySelector(`div[aria-label="clue-${cellData.down}-down"]`) as HTMLElement;
      if (clue) {
        downCluesRef.current!.scrollTo({ top: clue.offsetTop, behavior: "smooth" });
      }
    }
  };

  const smallLayout = dims.width <= 8 && dims.height <= 8;
  const heightBreakpoints = smallLayout ? [undefined, undefined, "40vh"] : [undefined, undefined, "80vh"];
  const fontSizeBreakpoints = ["0.75rem", "0.75rem", "1rem"];

  return (
    <Box sx={{ width: ["95%", "95%", "85%"], mx: "auto", my: 5 }}>
      <Box sx={{ mt: 5, textAlign: "center", fontWeight: 500 }}>
        <Typography variant="h1" component="span" sx={{ fontWeight: 500 }}>
          {crossword.title}
        </Typography>
        <Typography variant="h2" component="span">
          •
        </Typography>
        <Typography
          variant="h1"
          component="span"
          sx={{ fontWeight: 500, color: "#808080" }}
        >{`By ${authors}`}</Typography>
        <Typography variant="h2" component="span">
          •
        </Typography>
        <Typography variant="h1" component="span" sx={{ fontWeight: 500, color: "#808080" }}>
          {dateStr}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: "1.25rem" }}>
          {crossword.description}
        </Typography>
        {crossword.commentary_link && (
          <Link
            href={crossword.commentary_link}
            underline="hover"
            sx={{ fontSize: "1.25rem", color: "rgb(0, 100, 200)" }}
            target="_blank"
          >
            Click here to view the solution to the puzzle.
          </Link>
        )}
      </Box>
      <Box sx={{ my: 1, display: "flex", justifyContent: "center" }}>
        <FreestarAdSlot
          publisher={"dailyprincetonian-com"}
          placementName={"dailyprincetonian_crosswords_home_page_atf"}
        />
      </Box>
      <Confetti
        width={width}
        height={height}
        run={showConfetti}
        recycle={false}
        onConfettiComplete={() => setShowConfetti(false)}
        gravity={0.5}
      />
      <CrosswordProvider
        data={clues}
        storageKey={crossword.id}
        theme={{
          highlightBackground: "#b1d7fb",
          otherHighlightBackground: "#dbedfd",
          focusBackground: "#f9db4a",
          allowNonSquare: true,
        }}
        ref={crosswordRef}
        onCrosswordComplete={handleComplete}
        onCellFocus={handleCellFocus}
      >
        <Stack direction="column">
          <Stack
            className={checking ? "show-correct" : ""}
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              height: heightBreakpoints,
              mt: { xs: undefined, md: "50px" },
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: undefined, md: "30%" },
                display: "flex",
                flexDirection: { xs: "row", md: "column" },
                height: { xs: 150, md: "100%" },
                marginBottom: { xs: 6, md: undefined },
                position: { xs: "sticky", md: "unset" },
                top: { xs: 0, md: "unset" },
                zIndex: 999,
                background: "white",
              }}
            >
              <Box
                sx={{
                  maxHeight: { xs: "100%", md: "50%" },
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "50%", md: "100%" },
                }}
              >
                <Typography variant="h6" sx={{ mb: 0, fontSize: { xs: 12, md: 20 } }}>
                  Across
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    pr: 2,
                    overflow: "scroll",
                    flexShrink: 10,
                    flexGrow: 0,
                    // maxHeight: "50%",
                    fontSize: fontSizeBreakpoints,
                  }}
                  ref={acrossCluesRef}
                >
                  <DirectionClues direction="across" label={" "} />
                </Box>
              </Box>
              <Box
                sx={{
                  maxHeight: { xs: "100%", md: "50%" },
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "50%", md: "100%" },
                }}
              >
                <Typography variant="h6" sx={{ mb: 0, fontSize: { xs: 12, md: 20 } }}>
                  Down
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    pr: 2,
                    overflow: "scroll",
                    flexShrink: 10,
                    flexGrow: 0,
                    // maxHeight: "50%",
                    fontSize: fontSizeBreakpoints,
                  }}
                  ref={downCluesRef}
                >
                  <DirectionClues direction="down" label={" "} />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                width: { xs: "100%", md: undefined },
                margin: { xs: "auto", md: "0" },
              }}
              ref={onContainerRefChange}
            >
              <CrosswordGrid />
              <Stack
                direction="row-reverse"
                spacing={1}
                sx={{ my: 2, width: "200%", position: "absolute", top: "-60px", right: "0px" }}
              >
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setChecking((checking) => !checking);
                  }}
                >
                  {checking ? "Hide Checks" : "Check"}
                </Button>
                <Button
                  onClick={() => crosswordRef.current?.fillAllAnswers()}
                  disableElevation
                  variant="contained"
                  color="secondary"
                >
                  Reveal
                </Button>
                <Button
                  onClick={() => crosswordRef.current?.reset()}
                  disableElevation
                  variant="contained"
                  color="secondary"
                >
                  Clear
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </CrosswordProvider>
      <Box sx={{ my: 1, display: "flex", justifyContent: "center" }}>
        <FreestarAdSlot
          publisher={"dailyprincetonian-com"}
          placementName={"dailyprincetonian_crosswords_home_page_btf"}
        />
      </Box>
    </Box>
  );
};

export default CrosswordPage;
