import CrosswordPage from "pages/crosswordPage/CrosswordPage";
import LegacyReroute from "pages/legacyReroute/LegacyReroute";
import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";

import Landing from "../pages/landing/Landing";

interface RouteSchema {
  path: string;
  component: JSX.Element;
}

// note that we'll have page components in the future so the components here will just be one reference
const routes: RouteSchema[] = [
  {
    path: "*",
    component: (
      <>
        <h1>404</h1>
        <Link to="/">Back to home</Link>
      </>
    ),
  },
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: "/minis",
    component: <Landing mini />,
  },
  {
    path: "/:id",
    component: <CrosswordPage />
  },
  {
    path: "/puzzle/:legacy_id",
    component: <LegacyReroute />
  },
];

const Router = () => {
  return (
    <Routes>
      {routes.map((route: RouteSchema) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

export default Router;
