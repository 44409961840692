import { createTheme, Theme } from "@mui/material/styles";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#0064c8",
    },
    secondary: {
      main: "#f4f4f4",
    },
  },
  typography: {
    fontFamily: "franklin-gothic-urw, sans-serif",
    h1: {
      fontWeight: "bold",
      fontSize: "2em",
      color: "black",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      color: "black",
      margin: ".25rem",
    },
    h3: {
      fontWeight: "500",
      fontSize: "1rem",
      color: "grey",
    },
  },
});

export default theme;