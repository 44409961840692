import { Theme } from "@mui/material/styles";

interface TileStylesProps {
  variant: "primary" | undefined;
}

const styles = (theme: Theme, props: TileStylesProps) => ({
  card: {
    boxShadow: 0,
    alignItems: "center",
    textAlign: "center",
    borderRadius: "10px",
    border: () => props.variant === "primary" ? 0 : 1.5,
    borderColor: "#f15a24",
    padding: "1rem",
    margin: () => props.variant === "primary" ? "1rem" : ".75rem",
    width: () => props.variant === "primary" ? "75vw" : "60vw",
    [theme.breakpoints.up("sm")]: {
      width: () => props.variant === "primary" ? "75vw" : "45vw",
    },
    [theme.breakpoints.up("md")]: {
      width: () => props.variant === "primary" ? "45vw" : "20vw",
    },
    h2: {
      fontSize: () => props.variant === "primary" ? "2rem" : theme.typography.h2.fontSize,
    },
    h3: {
      fontSize: () => props.variant === "primary" ? "1.25rem" : theme.typography.h3.fontSize,
    }
  },
  box: {
    maxHeight: "50vh",
    width: () => props.variant === "primary" ? "75vw" : "60vw",
    [theme.breakpoints.up("sm")]: {
      width: () => props.variant === "primary" ? "75vw" : "45vw",
    },
    [theme.breakpoints.up("md")]: {
      width: () => props.variant === "primary" ? "45vw" : "20vw",
    },
  }
});

export default styles;