import * as React from "react";

const Footer = () => {
  return (
    <footer className="bg-light mt-4 py-4 w-100 mb-0">
      <div className="container">
          <div className="row">
              <div className="col-12 col-md-2 text-center maxWidth100">
                  <a href="/"><img src="assets/images/tiger.png" className="d-block mx-auto mb-2" alt="The Princetonian" /></a>
                  <ul className="list-inline">
                      <li className="list-inline-item"><a href="https://twitter.com/princetonian" target="_blank" rel="noreferrer"><i className="fab fa-twitter" aria-label="Twitter"></i></a></li>
                      <li className="list-inline-item"><a href="https://www.facebook.com/DailyPrincetonian" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f" aria-label="Facebook"></i></a></li>
                      <li className="list-inline-item"><a href="https://www.instagram.com/dailyprincetonian/" target="_blank" rel="noreferrer"><i className="fab fa-instagram" aria-label="Instagram"></i></a></li>
                      <li className="list-inline-item"><a href="https://vm.tiktok.com/ZMeNbh7Hc/" target="_blank" rel="noreferrer"><i className="fab fa-tiktok"></i></a></li>
                      <li className="list-inline-item"><a href="https://open.spotify.com/show/5xeaopGvoY0teJn0atpNPM" target="_blank" rel="noreferrer"><i className="fab fa-spotify" aria-label="Spotify"></i></a></li>
                      <li className="list-inline-item"><a href="https://youtube.com/channel/UC6DZuLRHcb2Nf6bP3nna9VQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube" aria-label="youtube"></i></a></li>
                  </ul>
                  <a href="{{ 'page/subscribe'|url }}" className="btn btn-primary btn-sm text-uppercase text-uppercase" target="_blank" style={{ borderColor: "#e87722", backgroundColor: "#e87722" }}>Subscribe</a>
              </div>
              <div className="col-12 col-md-10 mt-2 mt-md-0 text-center text-md-left" style={{ borderLeft: "1px solid black" }}>
                  <div className="row">
                      <div className="col-12 col-md-2 mb-2">
                          <ul className="list-unstyled">
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/news" className=" font-weight-bold">News</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/u-affairs" className="">U. Affairs</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/student-life" className="">Student Life</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/graduate-school" className="">Grad School</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/local" className="">Local</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/alumni" className="">Alumni</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/q-a" className="">Q&amp;A</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/activism" className="">Activism</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/usg" className="">USG</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/research" className="">Research</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/academics" className="">Academics</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/obituaries" className="">Obituaries</a></li>
                          </ul>
                      </div>
                      <div className="col-12 col-md-2 mb-2">
                          <ul className="list-unstyled">
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/sports" className=" font-weight-bold">Sports</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/sports-news" className="">Sports News</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/sports-inside-looks" className="">Inside Looks</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/sports-profile" className="">Profiles</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/on-tap" className="">On Tap</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/sports-columns" className="">Columns</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/sports-previews" className="">Previews</a></li>
                              <li className="list-unstyled-item mb-2"><a href="https://www.dailyprincetonian.com/section/sports-recap" className="">Recaps</a></li>
                              <li className="list-unstyled-item mb-2"><a href="https://www.dailyprincetonian.com/section/cartoon" className="font-weight-bold">Cartoon</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/newsletter" className=" font-weight-bold">Newsletter</a></li>
                              <li className="list-unstyled-item"><a href="https://subscribe.dailyprincetonian.com/" target="_blank" className="" rel="noreferrer">Subscribe</a></li>
                          </ul>
                      </div>
                      <div className="col-12 col-md-2 mb-2">
                          <ul className="list-unstyled">
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/opinion" className=" font-weight-bold">Opinion</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/columns" className="">Column</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/letter-to-the-editor" className="">Letter to the Editor</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/letter-from-the-editor" className="">Letter from the Editor</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/guest-contributions" className="">Guest Submission</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/open-letter" className="">Open Letter</a></li>
                              <li className="list-unstyled-item mb-2"><a href="https://www.dailyprincetonian.com/section/editorial" className="">Editorial</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/projects" className=" font-weight-bold">Projects</a></li>
                              <li className="list-unstyled-item"><a href="https://crossword.dailyprincetonian.com/" className="">Puzzles</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/special" className="">Special Issues</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/humor" className="">Humor</a></li>
                          </ul>
                      </div>
                      <div className="col-12 col-md-2 mb-2">
                          <ul className="list-unstyled">
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/prospect" className=" font-weight-bold">The Prospect</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/arts" className="">Arts</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/entertainment" className="">Entertainment</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/lifestyle" className="">Lifestyle</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/self" className="">Self</a></li>
                              <li className="list-unstyled-item mb-2"><a href="https://www.dailyprincetonian.com/section/features" className=" font-weight-bold">Features</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/multimedia" className=" font-weight-bold">Multimedia</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/multimedia/video" className="">Video</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/page/podcast" className="">Podcast</a></li>
                              <li className="list-unstyled-item mb-2"><a href="https://www.dailyprincetonian.com/section/visual-essays" className="">Visual Essays</a></li>
                              <li className="list-unstyled-item"><a href="https://www.dailyprincetonian.com/section/special" className=" font-weight-bold">Special</a></li>
                          </ul>
                      </div>
                      <div className="col-12 col-md-2 mb-2">
                          <ul className="list-unstyled">
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/masthead">Masthead</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/contact">Contact Us</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/about">About</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/advertise">Advertise</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://theprince.princeton.edu/" target="_blank" rel="noreferrer">Archives</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/submit">Submit a Tip</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/donate-to-the-daily-princetonian">Donate</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/privacy">Privacy</a></li>
                              <li className="list-unstyled-item"><a className="" href="https://www.dailyprincetonian.com/page/terms-and-conditions">Terms</a></li>
                          </ul>
                      </div>
                  </div>
                  <div className="row mt-4 text-center">
                      <div className="col-12">
                          <div className="mb-2">
                              <small>&copy; {new Date().getFullYear()} The Daily Princetonian</small>
                          </div>
                          <small>Powered by <img src="https://d1q35ni5859stt.cloudfront.net/2fb135af0da4595a658f1f4b62691724/dist/img/SNWorks.svg" style={{ position: "relative", top:"-3px", padding: "0 10px", maxWidth: "100px" }} alt="SNWorks"/> Solutions by The State News</small>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  );
};

export default Footer;
