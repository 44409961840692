import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Crossword } from "config/types";
import { Typography } from "@mui/material";

const LegacyReroute = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [crossword, setCrossword] = useState<Crossword | undefined>();
  useEffect(() => {
    fetch(`/api/crosswords/legacy/${params.legacy_id}`)
      .then((res) => res.json())
      .then((data) => {
        setCrossword(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params.legacy_id]);

  if (crossword !== undefined) {
    if (crossword.id !== undefined)
      navigate(`/${crossword.id}`, { replace: true });
    return <Typography sx={{ textAlign: "center" }}>Crossword not found. Please check your URL.</Typography>;
  }
  return <Typography sx={{ textAlign: "center" }}>Loading...</Typography>;
};

export default LegacyReroute;
