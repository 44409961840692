import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Router from "config/Router";
import theme from "config/theme";
import Header from "components/Header";
import Footer from "components/Footer";

import "styles.css";

export default function App() {
  // these pages are here for testing that routing works properly w the flask backend
  // remove as needed to build out the frontend
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header />
        <CssBaseline />
        <Router />
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}
