import { Theme } from "@mui/material/styles";

const styles = (theme: Theme) => ({
  latestText: {
    marginTop: "1rem",
    fontWeight: "500",
    fontSize: "1.75rem",
    color: "white",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      "& br" : {
        display: "none",
      }
    },
  }
});

export default styles;
