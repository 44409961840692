import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Tile from "components/Tile";
import { Author, Crossword } from "config/types";

interface PreviousCrosswordsProps {
  previous: Crossword[];
  allAuthors: Map<string, Author>;
  mini?: boolean;
}

const PreviousCrosswords = ({ previous, allAuthors, mini }: PreviousCrosswordsProps) => {
  return (
    <Grid>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">PREVIOUS {mini ? "MINIS" : "CROSSWORDS"}</Typography>
      </Box>
      <Grid
        container
        sx={{
          width: "100vw",
          direction: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {previous.length ? previous.map((crossword) => {
          const authors = crossword.author_ids.map((authorId) => allAuthors.get(authorId)).join(" and ");
          return (
            <Grid item key={crossword.id}>
              <Tile id={crossword.id} title={crossword.title} author={authors} date={crossword.date} />
            </Grid>
          );
        }) : <Typography>No previous {mini ? "minis" : "crosswords"} found.</Typography>}
      </Grid>
    </Grid>
  );
};

export default PreviousCrosswords;
