import * as React from "react";
import theme from "config/theme";
import { Grid, Box, Typography } from "@mui/material";
import { Author, Crossword } from "config/types";
import Tile from "components/Tile";
import makeStyles from "../../styles/LatestCrosswordStyles";

interface LatestCrosswordProps {
  latest: Crossword;
  allAuthors: Map<string, Author>;
  mini?: boolean;
}

const LatestCrossword = ({ latest, allAuthors, mini }: LatestCrosswordProps) => {
  const styles = makeStyles(theme);

  const authors = latest.author_ids.map((authorId) => allAuthors.get(authorId)).join(" and ");

  return (
    <Grid
      container
      sx={{
        justifyContent: "space-evenly",
        alignContent: "center",
        alignItems: "center",
        width: "100vw",
        backgroundColor: "#f15a24",
      }}
    >
      <Grid item>
        <Typography sx={styles.latestText} variant="h1">
          THE LATEST <br />{mini ? "MINI" : "CROSSWORD"}
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tile variant="primary" id={latest.id} title={latest.title} author={authors} date={latest.date} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LatestCrossword;
