import * as React from "react";
import { Card, CardMedia, Typography, CardActionArea, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { CrosswordTileProps } from "config/types";
import makeStyles from "../styles/TileStyles";
import theme from "../config/theme";

function Tile({ id, title, author, date, variant }: CrosswordTileProps) {
  const styles = makeStyles(theme, { variant });
  const dateStr = new Date(date).toLocaleDateString("en-us", { timeZone: "UTC", dateStyle: "medium" });

  return (
    <Card sx={styles.card}>
      <CardActionArea>
        <Link to={`/${id}`} style={{ textDecoration: "none" }}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="h3" color="grey">
            {dateStr}
          </Typography>
          <Box sx={{ margin: "0.5em 0 0.7em 0" }}>
            <CardMedia
              component="img"
              style={{ width: "100%", height: "20vh", margin: "auto", objectFit: "contain" }}
              src={`assets/images/crossword.jpg`}
              alt="crossword image"
            />
          </Box>
          <Typography variant="h3" color="grey">
            By {author}
          </Typography>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default Tile;
