import * as React from "react";
import { AppBar, Container, Toolbar, Typography, Box, MenuItem, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import makeStyles from "../styles/HeaderStyles";
import theme from "../config/theme";

const pages = [
  { title: "CROSSWORDS", href: "/" },
  { title: "MINIS", href: "/minis" },
];

function ResponsiveAppBar() {
  const styles = makeStyles(theme);
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
  // const handleCloseNavMenu = () => setAnchorElNav(null);

  return (
    <AppBar position="static" color="secondary" sx={{ backgroundColor: "white", boxShadow: "0px 0px 4px" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ display: "flex" }}>
          <Typography
            noWrap
            sx={[styles.princeText, { display: { xs: "none", sm: "flex" } }]}
            component={Link}
            href="https://dailyprincetonian.com"
          >
            THE DAILY PRINCETONIAN&nbsp;&nbsp;&nbsp;
          </Typography>

          {/* Mobile */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none", alignItems: "center", justifyContent: "end" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" component={RouterLink} to={page.href}>{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {/* Desktop */}
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {pages.map((page, i) => (
              <React.Fragment key={page.title}>
                {i === 0 ? (
                  <Typography sx={[styles.princeText, { display: { xs: "none", sm: "inherit" } }]}>|</Typography>
                ) : (
                  <Typography sx={styles.princeText}>|</Typography>
                )}
                <MenuItem key={page.title}>
                  <Typography component={RouterLink} sx={styles.crosswordText} textAlign="center" to={page.href}>
                    {page.title}
                  </Typography>
                </MenuItem>
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;