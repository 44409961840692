import { Theme } from "@mui/material/styles";

const styles = (theme: Theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100vw",
    padding: "0.75rem 0",
    position: "sticky",
    borderBottom: "1.5px solid lightgrey",
  },
  princeText: {
    fontFamily: "minion-pro",
    fontSize: "1.5rem",
    color: "black !important",
    textDecoration: "none",
  },
  crosswordText: {
    fontFamily: "franklin-gothic-urw, sans-serif",
    fontSize: "1.25rem",
    fontWeight: "500",
    color: "black !important",
    textDecoration: "none",
  },
});

export default styles;
