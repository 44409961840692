import * as React from "react";
import { useEffect, useState } from "react";
import AdBanner from "components/AdBanner";
import LatestCrossword from "./LatestCrossword";
import PreviousCrosswords from "./PreviousCrosswords";
import { Author, Crossword } from "config/types";
import { Box, Typography } from "@mui/material";
import FreestarAdSlot from "components/freestar";

const Landing = ({ mini = false }: { mini?: boolean }) => {
  const [crosswords, setCrosswords] = useState<Crossword[] | undefined>();
  const [authors, setAuthors] = useState<Map<string, Author>>();
  useEffect(() => {
    setCrosswords(undefined); // if mini changes, reset crosswords
    fetch(`/api/crosswords?mini=${mini}`).then((res) => res.json()).then((data) => {
      setCrosswords(data);
    }).catch((err) => {
      console.error(err);
    });
  }, [mini]);
  useEffect(() => {
      fetch("/api/authors").then((res) => res.json()).then((data) => {
      const authorsMap = new Map();
      data.forEach((author: Author) => {
        authorsMap.set(author.id, `${author.first_name} ${author.last_name}`);
      });
      setAuthors(authorsMap);
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  return (
    <>
      <Box sx={{ my: 1, display: "flex", justifyContent: "center" }}>
        <FreestarAdSlot
          publisher={"dailyprincetonian-com"}
          placementName={"dailyprincetonian_crossword_puzzle_page_btf"}
        />
      </Box>
      {/* <AdBanner
        link="https://lectures.princeton.edu/lectures/2022/siddhartha-mukherjee"
        imgSrc="https://assets.dailyprincetonian.com/crossword.dailyprincetonian.com/partners/mukherjee_crossword.jpg"
      /> */}
      {crosswords && authors ? (
        crosswords.length && authors.size ? (
          <>
            <LatestCrossword latest={crosswords[0]} allAuthors={authors} mini={mini} />
            <PreviousCrosswords previous={crosswords.slice(1)} allAuthors={authors} mini={mini} />
          </>
        ) : (
          <Typography sx={{ textAlign: "center", mt: 3 }} variant="body1">
            No crosswords found
          </Typography>
        )
      ) : (
        <Typography sx={{ textAlign: "center", mt: 3 }} variant="body1">
          Loading...
        </Typography>
      )}
    </>
  );
};

export default Landing;
